/* eslint-disable @typescript-eslint/no-shadow */
import { Struct, define, refine, is } from 'superstruct';

export const required = <T>(struct: Struct<T, any>, messageP: string): Struct<T, any> => {
  const expected = `Expected required ${struct.type} but received empty`;

  return refine(struct, 'required', (value: any) => value.length > 0 || (messageP ?? expected));
};

export const message = <T>(struct: Struct<T, any>, message: string): Struct<T, any> => (
  define('message', (value) => (is(value, struct) ? true : message))
);

export const match = (field: string, message: string) => (
  define('match', (value, { branch }) => {
    if (!value) {
      return 'Required';
    }

    if (branch[0][field] === value) {
      return true;
    }

    return message;
  })
);

export const requiredDependsOn = (field: string, valToCompare: string) => (
  define('requiredDependsOn', (value: any, { branch }) => {
    if (branch[0][field] === valToCompare) {
      return typeof value !== 'undefined' && value.length > 0;
    }

    return true;
  })
);

export const requiredWithValues = (
  field: string,
  fieldRequiredValue: string,
  values: Array<string>,
) => define('requiredWithValues', (value: any, { branch }) => {
  const fieldValue = branch[0][field];
  const hasValue = typeof value !== 'undefined' && value.length > 0;
  const message = `Only users with ${field} that contains ${fieldRequiredValue} can have this role`;

  if (values.includes(value)) {
    return hasValue && fieldValue.includes(fieldRequiredValue) ? true : message;
  }

  return hasValue;
});

export const fileList = (message: string = 'Extension not allowed', ext?: string) => (
  define('fieldList', (value: any) => {
    const files = [...value as FileList];
    const checkValue = typeof ext === 'undefined' ? true : files.every((file) => file.type === ext);

    return checkValue || message;
  })
);

export const email = (messageP: string) => {
  // eslint-disable-next-line max-len
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return define('email', (value: any) => (emailRegex.test(value) ? true : messageP));
};

export const password = (messageP: string) => {
  const passRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-|]).{12,}$/;

  return define('email', (value: any) => (passRegex.test(value) ? true : messageP));
};

export const clientModules = () => define('clientmodules', (value: any) => {
  const isActiveNoDate = value.filter((value: any) => value.is_trial && value.is_active && !value.end_at);
  const hasDateIsNotActive = value.filter((value: any) => (
    value.is_trial && !value.is_active && value.end_at && !value.start_at
  ));

  if (isActiveNoDate.length || hasDateIsNotActive.length) {
    return {
      message: [...isActiveNoDate, ...hasDateIsNotActive].map((error: any) => error.name) as any,
    };
  }

  return true;
});
